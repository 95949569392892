import * as React from "react";
import Layout from "../components/Layout";
import "./tc.sass";

const PricePage = () => {
  return (
    <Layout>
      <div id="tc-container">
        <h2>Game - Terms and Conditions</h2>
        <p>
          Article I: Organization and context Webpanda (hereinafter referred to
          as the "Organizing Company" or "the organizer") is organizing a free
          game (hereinafter referred to as the "Game"). The Organizing Body is
          setting up this Game on behalf of partner restaurants and businesses
          with the aim of rewarding its visitors (hereinafter referred to
          individually as "the Player" and collectively as "the Players") who
          visit one of Weely's partner establishments (bar, restaurant, concert
          hall, cinema, beauty salon, wellness centre, etc.) participating in
          the Game. It is expressly understood that the role of the Organizing
          Company is limited to organizing the Game and delivering the prizes
          won. As Webpanda is not an institution, the Company will not be held
          responsible in this regard. The Game takes place exclusively on the
          Weely website. <br /> <br />
          Article II: Terms of participation Participation in the Game is
          reserved for Players of Weely's partner establishments. Participation
          is conditional upon the player spending money in the partner
          establishment. Weely and its partner establishments may request proof
          of payment (bill, invoice, receipt, etc.) from the players. Any Player
          who wishes to participate in the Game must : - Select the
          Establishment where he/she wishes to play; - Provide his/her email; -
          Perform one of the requested actions (follow the Establishment on
          Instagram, Facebook, leave a comment on Google or answer a question
          about the satisfaction of the visit; - Spin the virtual wheel made
          available on the website or app. Following participation in the game,
          the Player subscribes to news and exclusive offers from the
          Establishment through Weely. If he/she wishes, he/she can refuse this
          when registering for the game or afterwards. <br /> <br />
          Article III : Prize Depending on the Establishment, the prize of the
          Game may consist of a gift or prize offered by the Establishment. This
          prize will be available during the player's next visit to the
          establishment. Gift - If it is a prize offered by the establishment,
          the nature of the prize as well as the available stock are
          specifically defined by the establishment. It can be for example a
          drink offered within the framework of a next visit, or a strategy 1
          bought/1 offered, for example. <br /> <br />
          Article IV: Designation of winners The draws are made automatically
          following the participation thanks to a random algorithm. Once the
          virtual wheel is turned, the Player discovers his or her prize.
          Winners will receive a confirmation e-mail to show to the staff of the
          establishment to claim their winnings during their next visit. - If it
          turns out that a participant has won by not respecting the present
          rules, by fraudulent means, or by means other than those resulting
          from the process described by Weely or by violating one of the
          conditions of participation, he/she would then lose his/her quality of
          winner, without prejudice to any possible legal proceedings that could
          be brought against the participant by the Organizing Company. In this
          case, the participant will receive an email informing him/her of the
          loss of his/her winning status, including a reminder of these rules.{" "}
          <br /> <br />
          Article V: Conditions of participation Registration for the Game
          implies unconditional acceptance of and compliance with these rules,
          which are available at any time during the Game on the Weely website
          and on which the Organizing Body has the final say on any disputed
          cases and any difficulties of interpretation. In the event of
          rejection of all or part of these rules, it is the responsibility of
          the Players to refrain from participating in the Game - Participation
          is strictly nominative and the player may under no circumstances play
          under several pseudonyms or on behalf of other members. - It is
          understood that a participant is defined as a single physical person:
          any use of different member accounts by the same participant will be
          considered as an attempt at fraud resulting in the definitive
          elimination of the participant. - It is strictly forbidden for a
          participant to play from an email opened for another person. Only one
          and only one email must be used per physical person participating. -
          It is strictly forbidden, by any means whatsoever, to modify or
          attempt to modify the proposed game devices, in particular in order to
          modify the results or to influence the validity of the draw by an
          automated or unfair means. - In order to prevent the risk of fraud,
          each participant may only play once per Establishment on the same day.
          - Each entry is linked to a single bill or invoice. Therefore: - The
          same participant may only take part in the draw once per bill or
          invoice.The Organizing Company is authorized to make all necessary
          verifications in order to verify the absence of fraud. <br /> <br />
          Article VI: Awarding of prizes The terms and conditions of payment of
          the prize will be specified in an e-mail as soon as a participant
          acquires the status of winner. - In the case of prizes provided by the
          Establishment, it is the responsibility of the Establishment's staff
          to check compliance with the conditions of participation and to scan
          the prize received by e-mail. In the event that all or part of the
          winner's contact information proves to be manifestly false or
          erroneous, in particular his or her e-mail address or telephone
          number, it shall in no case be incumbent upon the Organizing Body to
          carry out research of any nature whatsoever to find the winner. The
          winner shall lose the benefit of his/her prize and shall not be
          entitled to claim any compensation. <br /> <br />
          Article VII: Applicable law and jurisdiction Participation in this
          Game implies unreserved acceptance of all the provisions of these
          rules. In the event of a dispute, only an e-mail to contact@weely.ch
          within 30 (thirty) days maximum after the date of participation will
          be admissible. Except in the case of obvious errors, it is agreed that
          the information resulting from the computer systems of the Organizing
          Body shall have evidentiary force in any dispute as to the conditions
          of connection, the submission of entries and the computer processing
          of said information relating to the Game. Participants are subject to
          the Swiss regulations applicable to games and contests. Any dispute
          that cannot be settled amicably shall be submitted to the competent
          courts of the Organizing Body's registered office, unless otherwise
          provided by public policy. In the event that one of the clauses of
          these rules is declared null and void, this shall in no way affect the
          validity of the rules themselves, and all other clauses shall retain
          their force and scope. For any question relating to the functioning of
          the game: contact@weely.ch
        </p>
      </div>
    </Layout>
  );
};

export default PricePage;
